import axios from "@/plugins/axios.js"

const  actions = {
  fetchGroups({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/groups?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_GROUPS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
  fetchGroup({ commit }, itemId) {
    return new Promise((resolve, reject) => {
      axios.get(`/groups/${itemId}/show`)
        .then((response) => {
          if(response.data.success){
              commit('SET_GROUP', response.data.data)
            }
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  fetchPosts({ commit, state}, payload = {}) {
    return new Promise((resolve, reject) => {
      axios.get(`/posts?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          if(response.data.success){
            commit('SET_POSTS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

const getters = {
  getPostData: (state, getters, rootState) => (type) => {
    let alphabets = getters.sortPostsAsc(state.group.posts.filter((post) => post.type == 'alphabet'));
    let otherTypes = state.group.posts.filter((post) => post.type != 'alphabet');
    let posts = [...alphabets, ...otherTypes].filter((post) => post.content.toLowerCase().includes(state.postSearchQuery.toLowerCase()))
    return type === 'quiz'
        ? getters.filterPassedQuizzes(posts.filter((p) => (p.type == 'quiz')))
        : type
            ? posts.filter((p) => p.type == type )
            : posts.filter((p) => p.type != 'quiz' )
    },

    groups: (state, getters) => (sort) => {
        let groups = state.pageData.data.all.filter((group) => group.name.toLowerCase().includes(state.pageData.dataearchQuery.toLowerCase()))
        return sort === 'desc'
                ? getters.sortGroupsAsc(groups).reverse()
                : getters.sortGroupsAsc(groups)
    },
    freeGroups: (state, getters) => (sort) => {
        let groups = state.pageData.data.free.filter((group) => group.name.toLowerCase().includes(state.pageData.dataearchQuery.toLowerCase()))
        return sort === 'desc'
                ? getters.sortGroupsAsc(groups).reverse()
                : getters.sortGroupsAsc(groups)
    },

    availableGroups: (state, getters) => (sort) => {
      return getters.groups(sort).filter((g) => g.accessType() == "none")
    },

    sortGroupsAsc: (_) => (items) => {
        items.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        return [...items]
    },

    sortPostsAsc: (_) => (items) => {
        items.sort((a,b) => (a.content > b.content) ? 1 : ((b.content > a.content) ? -1 : 0));
        return [...items]
    },

    filterPassedQuizzes: (state, getters) => (array) =>{
        let p_quizzes = [...state.group.passed_quizzes];
        let a_quizzes = array.filter((q) => {
            const count = getters.countOccurrences(p_quizzes, q.id);
            return count < 3
        })
        return getters.shufflePost(a_quizzes);

    },

    countOccurrences: (_) => (array, val) => array.reduce((a, v) => (v === val ? a + 1 : a), 0),

    shufflePost: (_)=> (array) => {
        var currentIndex = array.length, temporaryValue, randomIndex;
      // While there remain elements to shuffle...
      while (0 !== currentIndex) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
      }
      return [...array];
    },
}


const mutations = {
  SET_GROUPS(state, data) {
    state.pageData = data
  },
  SET_GROUP(state, group){
    state.group = group
  },
  UPDATE_GROUP(state, group){
    Object.assign(state.group, group)
  },
  UPDATE_BELONG(state, item){
    const dataIndex = state.pageData.data.findIndex((p) => p.id == item.id);
    Object.assign(state.pageData.data[dataIndex], item);
  },
  
  /**************** THIS IS FOR POSTS********************/
  SET_POSTS(state, data){
    state.postData = data
  },
  UPDATE_POST(state, post){
    const dataIndex = state.postData.data.findIndex((p) => p.id == post.id);
    Object.assign(state.postData.data[dataIndex], post);
  },
  DELETE_POST(state, itemId){
    const itemIndex = state.postData.data.findIndex((item) => item.id == itemId)
    state.postData.data.splice(itemIndex, 1)
  },

  /**************** THIS IS FOR ESSAYS********************/
  ADD_ESSAY(state, item){
    state.group.essays.unshift(item)
  },
  UPDATE_ESSAY(state, item){
    const dataIndex = state.group.essays.findIndex((e) => e.id == item.id);
    Object.assign(state.group.essays[dataIndex], item);
  },
}

const state = {
  pageData:{
    data:[]
  },
  group: {},
  postData: {
    data: []
  }
}


export default {
    isRegistered: true,
    namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}