export default [
  {
  // =============================================================================
  // MAIN LAYOUT ROUTES - Admin Routes and Previlege User
  // =============================================================================
    path: '',
    redirect: '/dashboard',
    component: () => import('@/layouts/Main.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@views/Dashboard.vue'),
        meta: {
          breadcrumb: [
            {
              text: "Dashboard",
              active: true
            }
          ],
          pageTitle: 'Welcome!',
          authRequired: true,
        }
      },
      {
        path: '/my-languages',
        name: 'my-languages',
        component: () => import('@views/groups/MyGroupList.vue'),
        meta: {
          breadcrumb: [
            {
              text: "Dashboard",
              to: { path: "/dashboard" }
            },
            {
              text: "Languages",
              active: true
            }
          ],
          pageTitle: "My Languages List",
          authRequired: true,
        },
      },
      {
        path: '/languages',
        name: 'languages',
        component: () => import('@views/groups/GroupList.vue'),
        meta: {
          breadcrumb: [
            {
              text: "Dashboard",
              to: { path: "/dashboard" }
            },
            {
              text: "Languages",
              active: true
            }
          ],
          pageTitle: "All Languages List",
          authRequired: true,
        },
      },
      {
        path: '/languages/:groupId(\\d+)',
        name: 'language-show',
        component: () => import('@views/groups/GroupShow.vue'),
        meta: {
          breadcrumb: [
            {
              text: "Languages",
              to: { path: "/my-languages" }
            },
            {
              text: "Show",
              active: true
            }
          ],
          pageTitle: "Language Group",
          authRequired: true,
        },
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('@views/account/UserEdit.vue'),
        meta: {
          breadcrumb: [
            {
              text: "Dashboard",
              to: { path: "/dashboard" }
            },
            {
              text: "Account",
              active: true
            }
          ],
          pageTitle: 'Profile',
          authRequired: true,
        },
      },
      {
        path: '/subscriptions',
        name: 'subscriptions',
        component: () => import('@views/subscriptions/SubscriptionList.vue'),
        meta: {
          breadcrumb: [
            {
              text: "Dashboard",
              to: {path: "/dashboard"}
            },
            {
              text: "Subscriptions",
              active: true
            }
          ],
          pageTitle: "Subscriptions list",
          authRequired: true,
        }
      },
      {
        path: '/subscriptions/:subId(\\d+)',
        name: 'view-subscription',
        component: () => import('@views/subscriptions/SubscriptionShow.vue'),
        meta: {
          breadcrumb: [
            {
              text: "Subscriptions",
              to: {path:"/subscription"}
            },
            {
              text: "View subscription",
              active: true
            }
          ],
          pageTitle: "View Subscription",
          authRequired: true,
        },
      },
      {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@views/notification/Notifications.vue'),
        meta: {
          breadcrumb: [
            {
              text: "Dashboard",
              to: {path: "/dashboard"}
            },
            {
              text: "Notification List",
              active: true
            }
          ],
          pageTitle: "Notifications",
          authRequired: true,
        }
      },
    ]
  },
  // =============================================================================
  // FULL PAGE LAYOUTS
  // =============================================================================
  {
    path: '',
    component: () => import('@/layouts/FullPage.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('@/views/pages/Login.vue'),
        meta: {
          pageTitle: "Login",
          guestRequired: true,
        }
      },
      {
        path: '/register',
        name: 'register',
        component: () => import('@/views/pages/Register.vue'),
        meta: {
          pageTitle: "Register",
          guestRequired: true,
        }
      },
      {
        path: '/error-404',
        name: 'error-404',
        component: () => import('@/views/pages/Error404.vue'),
      },
      {
        path: '/error-500',
        name: 'error-500',
        component: () => import('@/views/pages/Error500.vue'),
      },
    ]
  },
  // Redirect to 404 page, if no match found
  {
    path: '*',
    redirect: '/error-404'
  }
]