// en, de, fr, pt
export default {
	en: {
		Dashboard: 'Dashboard',
		ViewList: 'View List',
		Others: 'Others',
		Users: 'Users',
		Settings: 'Settings',
        JoinAGroup: 'Join a Group',
        CreateGroup: 'Create Group',
        ViewGroups: "View Groups",
        Languages: "Languages",
        Groups: "Groups",
		MyLanguages: "My languages",
		AllLanguages: "All languages"

    },
	de: {
		"Dashboard": "Dashboard",
		"ViewList": "Liste anzeigen",
		"Others": "Andere",
		"Users": "Benutzer",
		"Settings": "Einstellungen",
		"JoinAGroup": "Einer Gruppe beitreten",
		"CreateGroup": "Gruppe erstellen",
		"ViewGroups": "Gruppen anzeigen",
		"Languages": "Sprachen",
		"Groups": "Gruppen",
		"MyLanguages": "Meine Sprachen",
		"AllLanguages": "Alle Sprachen"
	},
	fr: {
		"Dashboard": "Tableau de bord",
		"ViewList": "Voir la liste",
		"Others": "Autres",
		"Users": "Utilisateurs",
		"Settings": "Paramètres",
		"JoinAGroup": "Rejoindre un groupe",
		"CreateGroup": "Créer un groupe",
		"ViewGroups": "Voir les groupes",
		"Languages": "Langues",
		"Groups": "Groupes",
		"MyLanguages": "Mes langues",
		"AllLanguages": "Toutes les langues"
    },
	pt: {
		"Dashboard": "Painel",
		"ViewList": "Ver Lista",
		"Others": "Outros",
		"Users": "Usuários",
		"Settings": "Configurações",
		"JoinAGroup": "Participar de um Grupo",
		"CreateGroup": "Criar Grupo",
		"ViewGroups": "Ver Grupos",
		"Languages": "Idiomas",
		"Groups": "Grupos",
		"MyLanguages": "Meus Idiomas",
		"AllLanguages": "Todos os Idiomas"
	}
}
