import themeConfig from "@/themeConfig.js"
import env from '../env.js'
import countries from './db/countries.js'
import { getUserData } from '@/auth/utils'

// ////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
    AppActiveUser : getUserData(),
    layout: {
        loader: false,
        layoutType: themeConfig.layoutType,
        layoutWidth: themeConfig.layoutWidth,
        leftSidebarType: themeConfig.leftSidebarType,
        leftSidebarSize: themeConfig.leftSidebarSize,
        menuPosition: themeConfig.menuPosition,
        topbar: themeConfig.topbar,
        pageHeader: themeConfig.pageHeader,
        pageFooter: themeConfig.pageFooter,
        contentPageFixed: themeConfig.contentPageFixed
    },
    paystackKey:env.PAYSTACK_PUBLIC_KEY,
    domain: env.domain,
    appName: env.appName,
    localUserKey: env.localUserKey,
    countries: countries,
    windowWidth: null,
    subscriptions: [],
}

export default state
