
<template>
	<div id="app" :class="vueAppClasses">
    <div v-if="layout.loader" class="busy-container position-relative">
      <div class="app-busy-mood text-center d-flex justify-content-center align-items-center">
        <img :src="absoluteUrl('/images/loading.gif')" width="60">
      </div>
    </div>
		<router-view />
	</div>
</template>

<script>

export default {
  data() {
    return {
      vueAppClasses: [],
    }
  },
  computed: {
    layout()  { return this.$store.state.layout },
  },
}

</script>

<style lang="scss">
  // Import Main styles for this application
  @import '@assets/scss/app';
</style>


<style type="text/css">
  .app-busy-mood{
    background: rgb(237 229 231);
    position: fixed;
    top: 0;
    z-index: 99999;
    opacity:0.4;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .font-sm{
    font-size:13px;
  }
  .cursor-pointer{
    cursor: pointer !important;
  }

 @media (min-width: 768px){
    body[data-sidebar-size=condensed]:not([data-layout=compact]):not(.auth-fluid-pages) {
        min-height: auto;
    }
 }
</style>
