
import axios from "@/plugins/axios.js"

const actions = {
  changeLoaderValue({ commit }, loader = false) {
    commit("TOGGLE_LOADER", loader);
  },
  changeLayoutType({ commit },layoutType) {
    return;
    commit("CHANGE_LAYOUT", layoutType);
  },

  changeLayoutWidth({ commit }, layoutWidth ) {
    commit("CHANGE_LAYOUT_WIDTH", layoutWidth);
  },

  changeLeftSidebarType({ commit }, leftSidebarType) {
    commit("CHANGE_LEFT_SIDEBAR_TYPE", leftSidebarType);
  },

  changeLeftSidebarSize({ commit }, leftSidebarSize) {
    commit("CHANGE_LEFT_SIDEBAR_SIZE", leftSidebarSize);
  },

  changeTopbar({ commit }, topbar) {
    commit("CHANGE_TOPBAR", topbar);
  },

  changeMenuPosition({ commit }, menuPosition) {
    commit("CHANGE_POSITION", menuPosition);
  },

  togglePageHeaderFooter({ commit }, payload) {
    commit("TOGGLE_HEADER_FOOTER", payload);
  },

  logoutUser({ commit, rootState }, action) {
    commit("LOGOUT_USER")
    return new Promise((resolve, reject) => {
      axios.post(`${rootState.domain}/api/logout`)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },

  universalFetch({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(action)
      .then((response) => {
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////
  fetchActiveUser({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get("/account/show")
      .then((response) => {
          if(response.data.success){
            commit('UPDATE_USER_INFO', response.data.data)
          }
          resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchCountries({ commit, rootState }, action) {
    return new Promise((resolve, reject) => {
      axios.get(`${rootState.domain}/app/countries`)
      .then((response) => {
        if(response.data.success){
          commit('SET_COUNTRIES', response.data.data)
        }
        resolve(response)
      }).catch((error) => { reject(error) })
    })
  },
  fetchSubscriptions({ commit}) {
    return new Promise((resolve, reject) => {
      axios.get("/subscriptions")
        .then((response) => {
          if(response.data.success){
            commit('SET_SUBSCRIPTIONS', response.data.data)
          }
          resolve(response)
        }).catch((error) => { reject(error) })
    })
  },
}

export default actions
